import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import exportValue from "../apiconfig";
import { borderRadius } from "@mui/system";
import moment from "moment";
import Moment from "react-moment";
const Sub_header = () => {  
  let navigate = useNavigate();

  const[state,setState]= React.useState({notification:[],count:0})
  
  let loginDetail = localStorage.getItem('admin_login');
 // const [state, setState] = useState({ full_name: "" });
 
  //console.log("e => ", loginDetail);
  if(loginDetail == null) {
    window.location.href = '/login';
    //navigate('/login')
  }else {    
    loginDetail = JSON.parse(localStorage.getItem('admin_login'));
   // setState(JSON.parse(loginDetail))
  }
  const logout = () => {
    console.log("e => ");
    localStorage.removeItem('admin_login');
    localStorage.clear();
    navigate('/login')
 
    
}

const notification=()=>{
     
  let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/notification_find`;
 let send={
  find:"",

  indexValue: 0,
  limit:5
 }
  
  axios.post(full_api, send,{ headers: exportValue.headers }).then((res) => {
     // console.log("res ", res);
   
      setState({...state,notification:res.data.output,count:res.data.count})
     // console.log("res",res)
    
     
  }).catch((e) => {

  });
}

React.useEffect(()=>{
 // notification()
},[])

const To_payment =()=>{
  navigate('/payments')
}
const seen_payment =(notification_id)=>{
  console.log("notification_id",notification_id)

  let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/notification_update`;
  let send={
   notification_id:notification_id,
   seen:1,
   
  }
  console.log("send",send)
   
   axios.post(full_api, send,{ headers: exportValue.headers }).then((res) => {
       console.log("res ", res);
       notification()
      //  setState({...state,notification:res.data.output,count:res.data.count})
      //  console.log("res",res)
     
      
   })
   .catch((e) => {
 
   });
}

const notification_bell =()=>{
  // console.log("notification_id",notification_id)

  let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/notification_batch_count_update`;
  let send={
  
   batch_count:0,
   
  }
  console.log("send",send)
   
   axios.post(full_api, send,{ headers: exportValue.headers }).then((res) => {
       console.log("res ", res);
       notification()
      //  setState({...state,notification:res.data.output,count:res.data.count})
      //  console.log("res",res)
     
      
   })
   .catch((e) => {
 
   });
}

const view_all =()=>{
  // console.log("notification_id",notification_id)

  let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/view_all`;
  let send={
  
   
   seen:1
   
  }
  console.log("send",send)
   
   axios.post(full_api, send,{ headers: exportValue.headers }).then((res) => {
       console.log("res ", res);
       notification()
      //  setState({...state,notification:res.data.output,count:res.data.count})
      //  console.log("res",res)
     
      
   })
   .catch((e) => {
 
   });
}
const userListdateTime = (dateTimeStr) => {
//    console.log("created",dateTimeStr);
//    let dbDate = dateTimeStr;

// console.log('getTime value =>', dbDate);
// let date = moment(dateTimeStr * 1000).getDate();
//    console.log("date",date)
//   //  let time = moment(dateTimeStr * 1000).format("hh:mm:ss");
//   // console.log("time ------ ",time);

// let parsedDate = date 

// console.log('parsed date =>', parsedDate);

// console.log('Date =>', getDate(parsedDate));
// console.log('Hours =>', getHours(parsedDate));
// console.log('Minutes =>', getMinutes(parsedDate));
// //   let newDate = Date.now();
//   let todaydate = moment(newDate).format("DD-MM-YY");
//    let date = moment(dateTimeStr * 1000).format("YY-MM-DD");
//    console.log("date",date)
//    let time = moment(dateTimeStr * 1000).format("hh:mm:ss");
//   console.log("time ------ ",time);
//     // return moment(new Date()).fromNow();

//  return moment({dateTimeStr}).local().startOf('seconds').fromNow()  
  //  return (todaydate != date) ? date +"days ago" : time + "hrs ago";
}
const MONTH_NAMES = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];
function getFormattedDate(date, prefomattedDate = false, hideYear = false) {
  const day = date.getDate();
  const month = MONTH_NAMES[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours();
  let minutes = date.getMinutes();

  if (minutes < 10) {
    // Adding leading zero to minutes
    minutes = `0${ minutes }`;
  }

  if (prefomattedDate) {
    // Today at 10:20
    // Yesterday at 10:20
    return `${ prefomattedDate } at ${ hours }:${ minutes }`;
  }

  if (hideYear) {
    // 10. January at 10:20
    return `${ day }. ${ month } at ${ hours }:${ minutes }`;
  }

  // 10. January 2017. at 10:20
  return `${ day }. ${ month } ${ year }. at ${ hours }:${ minutes }`;
}


function timeAgo(created) {
  // console.log("date",date)
  // console.log("time",time)
  
  // let newdate = new Date();
  // console.log("newdate",newdate) 
  let date1 = moment(created *1000).format("YYYY-MM-DD HH:mm:ss");
 // console.log("date1",date1)
 
 
  return moment(date1).local().startOf('seconds').fromNow()


  
//   console.log("dateparams",typeof dateParam)
//   if (!dateParam) {
//     return null;
//   }
//   let date1 = moment(dateParam * 1000).format("YY-MM-DD");
// console.log("date1",date1)
//   let date = typeof dateParam === 'object' ? dateParam : new Date(date1);
//   console.log("date",date)
//   let  DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
//   let  today = new Date();
//   console.log("today",today)
//   let yesterday = new Date(today - DAY_IN_MS);
//   console.log("yesterday",yesterday)
//    let seconds = Math.round((today - date) / 1000);
//    console.log("seconds",seconds)
//    let  minutes = Math.round(seconds / 60);
  // let isToday = today.toDateString() === date.toDateString();
  // let isYesterday = yesterday.toDateString() === date.toDateString();
  // let isThisYear = today.getFullYear() === date.getFullYear();


  // if (seconds < 5) {
  //   return 'now';
  // } else if (seconds < 60) {
  //   return `${ seconds } seconds ago`;
  // } else if (seconds < 90) {
  //   return 'about a minute ago';
  // } else if (minutes < 60) {
  //   return `${ minutes } minutes ago`;
  // } else if (isToday) {
  //   return getFormattedDate(date, 'Today'); // Today at 10:20
  // } else if (isYesterday) {
  //   return getFormattedDate(date, 'Yesterday'); // Yesterday at 10:20
  // } else if (isThisYear) {
  //   return getFormattedDate(date, false, true); // 10. January at 10:20
  // }

  // return getFormattedDate(date); // 10. January 2017. at 10:20
}

  return (
    <>
    <section class="bg-white border-bottom">
  <div class="container-fluid">

    <section>
      <nav class="navbar navbar-expand-sm">
        <div class="container-fluid">
          <a class="navbar-brand" href="/"><h5>ShipRocket</h5></a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="mynavbar">
            <ul class="navbar-nav me-auto">
              <li class="nav-item">
                {/* <a class="nav-link" href="javascript:void(0)">Link</a> */}
              </li>
            </ul>
            <ul class="navbar-nav align-items-center">
              <li class="nav-item dropdown">
                 <a class="nav-link text-dark notification-bell unread dropdown-toggle" data-unread-notifications="true" href="#" role="button" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
                    <svg class="icon icon-sm text-gray-900" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                       <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z"></path>
                    </svg>
                 </a>
                 <div class="dropdown-menu dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                    <div class="list-group list-group-flush">
                       <a href="#" class="text-center text-primary fw-bold border-bottom border-light py-3">Notifications</a>
                       <a href="payments.php" class="list-group-item list-group-item-action border-bottom">
                          <div class="row align-items-center">
                             <div class="col-auto">
                               
                                <img alt="Image placeholder" src="../../../assets/img/dollar.png" class="avatar-md rounded"/>
                             </div>
                             <div class="col ps-0 ms-2">
                                <div class="d-flex justify-content-between align-items-center">
                                   <div>
                                      <h4 class="h6 mb-0 text-small">$38</h4>
                                   </div>
                                   <div class="text-end">
                                      <small class="text-danger">2 hrs ago</small>
                                   </div>
                                </div>
                                <p class="font-small mt-1 mb-0">Payment received for shipment #928343248</p>
                             </div>
                          </div>
                       </a>
                       <a href="payments.php" class="dropdown-item text-center fw-bold rounded-bottom py-3">
                          <svg class="icon icon-xxs text-gray-400 me-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                             <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
                             <path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd"></path>
                          </svg>
                          View all
                       </a>
                    </div>
                 </div>
              </li>
              <li class="nav-item dropdown ms-lg-3">
                 <a class="nav-link dropdown-toggle pt-1 px-0" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <div class="media d-flex align-items-center">
                       <img class="avatar rounded-circle" alt="Image placeholder" src="../../../assets/img/team/profile-picture-2.jpg"/>
                       <div class="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                          <span class="mb-0 font-small fw-bold text-gray-900">Welcome Admin!</span>
                       </div>
                    </div>
                 </a>
                 <div class="dropdown-menu dashboard-dropdown dropdown-menu-end mt-2 py-1">
                    <a class="dropdown-item d-flex align-items-center" href="/my_profile">
                       <svg class="dropdown-icon text-gray-400 me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clip-rule="evenodd"></path>
                       </svg>
                       My Profile
                    </a>
                    <a class="dropdown-item d-flex align-items-center" href="/change_password">
                       <svg class="dropdown-icon text-gray-400 me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd"></path>
                       </svg>
                       Change Password
                    </a>
                    <div role="separator" class="dropdown-divider my-1"></div>
                    <a class="dropdown-item d-flex align-items-center" href="#">
                       <svg class="dropdown-icon text-danger me-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path>
                       </svg>
                       Logout
                    </a>
                 </div>
              </li>
           </ul>
          </div>
        </div>
      </nav>
    </section>
  
  </div>
</section>

<section class="bg-white border-bottom">
  <div class="brainsol_custom">


    <nav class="navbar navbar-expand-sm ">
      <div class="container-fluid ">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="collapsibleNavbar">
          <ul class="navbar-nav me-auto ">
            <li class="nav-item active">
              <a class="nav-link" href="/"><i class="material-icons-outlined ">home</i> <span>Home</span></a>
            </li>
             
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"><i class="material-icons-outlined ">widgets</i> <span>Shipments</span><i class="material-icons-outlined">expand_more</i></a>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="/shipments">All Shipments</a></li>
                {/* <li><a class="dropdown-item" href="#">Delivered Shipments</a></li>
                <li><a class="dropdown-item" href="#">All Shipments</a></li>
                <li><a class="dropdown-item" href="#">Add New Shipment</a></li> */}
              </ul>
            </li>
            
            
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"><i class="material-icons-outlined ">people_outline</i> <span>Customers</span><i class="material-icons-outlined">expand_more</i></a>
              <ul class="dropdown-menu">
              <li><a class="dropdown-item" href="/customers">All Customers</a></li>
                <li><a class="dropdown-item" href="/customers?type=1">Customers</a></li>
                {/* <li><a class="dropdown-item" href="/customers">Service Providers</a></li> */}
                <li><a class="dropdown-item" href="/customers?type=2">Agents</a></li>
                
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" ><i class="material-icons-outlined ">local_atm</i> <span>Payments</span><i class="material-icons-outlined">expand_more</i></a>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="/payments">Payments Management</a></li>
                <li><a class="dropdown-item" href="/user_cod_wallet">User COD Wallet</a></li>
                {/* <li><a class="dropdown-item" href="/payments">Invoice Customers</a></li>
                <li><a class="dropdown-item" href="/payments">Invoice Agents</a></li> */}
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"><i class="material-icons-outlined ">person_outline</i> <span>Users</span><i class="material-icons-outlined">expand_more</i></a>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="/users">Staff Management</a></li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"><i class="material-icons-outlined ">settings</i> <span>System Settings</span><i class="material-icons-outlined">expand_more</i></a>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="/system_settings">Company Settings</a></li>
                <li><a class="dropdown-item" href="/social_settings">Social Settings</a></li>
                <li><a class="dropdown-item" href="/payment_settings">Payment Gateway Settings</a></li>
                <li><a class="dropdown-item" href="/smtp_settings">SMTP Settings</a></li>
                <li><a class="dropdown-item" href="/country_list">Location Settings</a></li>
                <li><a class="dropdown-item" href="/email_template">Email Template Settings</a></li>
                {/* <li><a class="dropdown-item" href="/spam_report">Spam</a></li>
                <li><a class="dropdown-item" href="/tickets">Ticket</a></li> */}
                <li><a class="dropdown-item" href="/cms_settings">CMS</a></li>
              </ul>
            </li> 
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"><i class="material-icons-outlined ">settings</i> <span>Master Settings</span><i class="material-icons-outlined">expand_more</i></a>
              <ul class="dropdown-menu">
             
              <li><a class="dropdown-item" href="/master">Master Settings</a></li>
               
              <li><a class="dropdown-item" href="/carrier_list">Carrier Managment</a></li>
                <li><a class="dropdown-item" href="/product">Product Managment</a></li>
              </ul>
            </li> 
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"><i class="material-icons-outlined ">settings</i> <span>Web Management</span><i class="material-icons-outlined">expand_more</i></a>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="/web_settings">Web Management</a></li>
                <li><a class="dropdown-item" href="/home_content">Services</a></li>
                <li><a class="dropdown-item" href="/testimonial">Testimonials</a></li>


                
              </ul>
            </li> 
          </ul>
           {/* <form class="d-flex">
            <input class="form-control me-2" type="text" placeholder="Search"/>
            <button class="btn btn-primary" type="button">Search</button>
          </form>  */}
        </div>
      </div>
    </nav>
  </div>
</section>




<main class="container-fluid"/>

       

  </>
  )
}

export default Sub_header