import React from 'react'
import Left_panel from '../Left_panel'
import Sub_header from '../Sub_header'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import exportValue from '../../apiconfig'
import Moment from 'react-moment';
import ReactStarsRating from 'react-awesome-stars-rating';
import '../../components/loader.css';
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';






const Shipment_detail = () => {
  const [value, setValue] = React.useState('1')
    const {invoice_id} = useParams();
  const [state, setState] = React.useState({ shipmentDetail: {sender:{},receiver:{},items:[] }, isLoading: true,detailFound:false,parcel_count:0  })
  const [addstate, setaddState] = React.useState({mode:"",transaction_id:""})
  const [editstate, seteditState] = React.useState({show:false,shipment_id:"",paid_to:""})
const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})



  const dimension_detail = () =>{
    let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/company_info_api`;
    let sendData = {};
  
    axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
      setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
      console.log("rnmmmmm", res);
    }).catch((e) => {
      // toast.configure()
       //toast.error("Some thing went wrong")
      console.log("----error:   ", e);
    })
  }
  React.useEffect(() => {
    dimension_detail();
    ShipmentDetail()
   
   
  }, [])

    const ShipmentDetail = () => {

        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/shipment_detail`;
        let sendData = { shipment_id: invoice_id };
        console.log("bb",sendData);
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
          console.log("res -> ",res);
         
         
            setState({ ...state, shipmentDetail: res.data.output[0],parcel_count:res.data.output[0].items.length, isLoading:false})
          
     
        }).catch((e) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            
          })
          console.log("----error:   ", e);
        });
      };
      React.useEffect(() => {
        ShipmentDetail();
      }, [])

      const nameFormat = (name)=> {
        
        if(name) {
            let nameArray =  name.split(" ");
           // console.log("nameArray  ",nameArray);
            if(nameArray.length > 1) {
                let n1 = nameArray[0].substring(0,1).toUpperCase();
                let n2 = nameArray[1].substring(0,1).toUpperCase();
                name = n1+n2;
            }else {
                name = nameArray[0].substring(0,2).toUpperCase();
            }
        }else{
            name = "NO";
        }
        return name;
    }
    const pay_to_transporter=(data_array)=>{
      if(addstate.mode!="" && addstate.transaction_id!=""){
      let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/pay_to_transporter`;
      let sendData={
        data_array,mode:addstate.mode,transaction_id:addstate.transaction_id
      }
      axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
        console.log("res -> ",res);
        if(res.data.status==200){
          Swal.fire(
              'Good job!',
              ' Payment Sent Successfully !',
              'success'
            )
            ShipmentDetail()
            seteditState({...editstate,show:false,shipment_id:"",paid_to:""})
          

       }
       else if (res.data.status==201){
        Swal.fire({
          icon: 'error',
        
          text: res.data.message,
          
        })
        ShipmentDetail()
        seteditState({...editstate,show:false,shipment_id:"",paid_to:""})
       }
      
      }).catch((e) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          
        })
        console.log("----error:   ", e);
      });}
      else{
        Swal.fire({
          icon: 'error',
        
          text: "Please Fill All Inputs",
          
        })
      }
     
    


    }
    const handleChange = (event) => {
      // event.persist()
       console.log("event is v",event.target.name);
       setaddState({
           ...addstate,
           [event.target.name]: event.target.value,
       })
   }
   const SenderAddress = () =>{
    if(state.shipmentDetail.items.length > 0){
      console.log("==>  ",state.shipmentDetail.items);
      return state.shipmentDetail.items.reduce((previousValue, currentValue) =>{
        return previousValue.item_weight + currentValue.item_weight},{item_weight:0}) 
    }else {
      return 0
    }
   } 
   
  return (
    <div>
      <Sub_header/>
   
    <div style={{marginLeft:"15px",marginRight:"15px"}}>
      
    <section class="">
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div class="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                        <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li class="breadcrumb-item">
                                <a href="#">
                                    <svg class="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                                </a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Shipment</li>
                        </ol>
                    </nav>
                    <h2 class="h4">Shipment# {invoice_id}</h2>
                    
                </div>
                <div class="btn-toolbar mb-2 mb-md-0">
                    <span class="badge rounded-pill bg-success">Delivered</span>
                </div>
            </div>
            
    </section>

    

    
    <section class="">
        <div class="row">
          <div class="col-md-8">
                
              
  
              
  
  
              <section>
                <h4>Tracking Status</h4>
                  <div class="card shadow-sm mb-3">
                    <div class="card-body">
                      <h3 class="fs-5 ">Start</h3>
                      <small class="text-muted">28 March 2022 21:29</small>
                      <p>Order has been confirmed by Carrier and assigned</p>
                      <hr/>
                      <h3 class="fs-5 ">Pickedup</h3>
                      <small class="text-muted">28 March 2022 21:29</small>
                      <p>Order has been Pickedup by Driver</p>
                      <hr/>
                      <h3 class="fs-5 ">Delivered</h3>
                      <small class="text-muted">28 March 2022 21:29</small>
                      <p>Order has been Delivered by Driver</p>
                    </div>
                  </div>
              </section>

              
              <section>
                <h4>Payment Info</h4>
                  <div class="card shadow-sm mb-3">
                    <div class="card-body">
                      <div class="d-flex justify-content-between">
                          <div class="">Shipment #<a href="" class="text-info">122323933</a> </div>
                          <div class=""><div class="text-end"><h3 class="fs-5 "><a href="" class="text-info">Customer Invoice #2983489</a></h3></div></div>
                      </div>
                      
                      
                      <hr/>
                      <table class="table">
                        <thead>
                          <tr>
                            <th colspan="2">Particulars</th>
                            <th>Amount ($)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Shipping Fee</td>
                            <td></td>
                            <td class=""><h6>{state.shipmentDetail.rate_price}</h6></td>
                          </tr>
                          <tr>
                            <td class="fw-700 text-end">Insurance (+)</td>
                            <td></td>
                            <td class=""><h6>{state.shipmentDetail.insurance_amount}</h6></td>
                          </tr>
                          <tr>
                            <td class="fw-700 text-end">VAT (18%)</td>
                            <td></td>
                            <td class=""><h6>{state.shipmentDetail.tax_amount}</h6></td>
                          </tr>
                          
                          
                          <tr>
                            <td class="fw-700 text-end">To be paid by customer</td>
                            <td></td>
                            <td class="text-danger"><h5>${state.shipmentDetail.total_amount}</h5></td>
                          </tr>
                        </tbody>
                      </table>
                      <hr/>
                      <div class="mt-3">
                        <h5>Payment information</h5>
                        <div class="row">
                            <div class="col-12 col-md">
                              <p>Transcation ID #</p>
                              <a href="">{state.shipmentDetail.transaction_id}</a>
                            </div>
                            <div class="col-12 col-md pay_mode text-end">
                              <p>Payment Mode</p>
                              <img src="https://i.imgur.com/7gg5305.png" alt="" class="img-fluid " title="Paypal"/> 
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </section>

              
            </div>

          <div class="col-md-4 pb-3 mb-3 shipment_info_23">
            <h4>Shipment information</h4>
            <section class="bg-white p-3 mb-3">
              <p>
                Jaipur <i class="fa fa-long-arrow-right" aria-hidden="true"></i> Delhi
                <span class="text-end"><small class="text-muted">Location last updated on 3 May 2022 09:38</small></span>
              </p>

              
              <p>Delivery ETA - <span class="text-muted">03-02-2022 @ 23:29</span></p>
            </section>


            

            <section class="bg-white">
                <section class="ps-4 pt-3">
                    <h3 class="fs-5 pb-3">Basic information</h3>
                    <div class="row">
                      <div class="col-md-6">
                        <small class="text-muted">Shipment Tracking #</small>
                        <h4 class="fs-5">{state.shipmentDetail.carrier_tracking_number}</h4>
                      </div>
                      <div class="col-md-6">
                        <small class="text-muted">PO/Refernce #</small>
                        <h4 class="fs-5">293848</h4>
                      </div>
                      <div class="col-md-6">
                        <small class="text-muted">Pickup</small>
                        <h4 class="fs-6">{state.shipmentDetail.sender.address+","+state.shipmentDetail.sender.city+" "+state.shipmentDetail.sender.country}</h4>
                      </div>
                      <div class="col-md-6">
                        <small class="text-muted">Delivery</small>
                        <h4 class="fs-6">{state.shipmentDetail.receiver.address+","+state.shipmentDetail.receiver.city+" "+state.shipmentDetail.receiver.country}</h4>
                      </div>
                      <div class="col-md-6">
                        <small class="text-muted">Sender</small>
                        <h4 class="fs-5"><a href="">{state.shipmentDetail.sender.name}</a></h4>
                      </div>
                      <div class="col-md-6">
                        <small class="text-muted">Reciver</small>
                        <h4 class="fs-5"><a href="">{state.shipmentDetail.receiver.name}</a></h4>
                      </div>
                      <div class="col-md-6">
                        <small class="text-muted">Type</small>
                        <h4 class="fs-5">{state.shipmentDetail.type}</h4>
                      </div>
                      <div class="col-md-6">
                        <small class="text-muted">Item(s)</small>
                        <h4 class="fs-5">{state.parcel_count}</h4>
                      </div>
                    </div>
                  </section>
        
                  <hr class="m-4"/>
        
        
                  <section class="ps-4">
                    <h3 class="fs-5 pb-3">Order details</h3>
                    <div class="row">
                      <div class="col-md-6">
                        <small class="text-muted">Carrier</small>
                        <h4 class="fs-5"><a href=""><img src={"https://shiprocketcdn.fniix.com/images/"+state.shipmentDetail.carrier_image} alt="" class="img-fluid" title="Fedex Inc."/></a></h4>
                        
                      </div>
                      <div class="col-md-6">
                        <small class="text-muted">Order on</small>
                        <h4 class="fs-5"><Moment format="MMM DD YYYY">
                        {new Date(state.shipmentDetail.created*1000)}
            </Moment> 
                        <small class="text-muted">:<Moment format="hh:mm">
                        {new Date(state.shipmentDetail.created*1000)}
            </Moment></small></h4>
                      </div>
                      <div class="col-md-6">
                        <small class="text-muted">Total Price #</small>
                        <h4 class="fs-5">$ {state.shipmentDetail.total_amount}</h4>
                      </div>
                      <div class="col-md-6">
                        <small class="text-muted">Total Weight</small>
                        <h4 class="fs-5">{<SenderAddress/> } KG</h4>
                      </div>
                    </div>
                  </section>
        
                  <hr class="m-4"/>
        
                  <section class="ps-4">
                    <h3 class="fs-5 pb-3">Service Inforamtion </h3>
                    <div class="row">
                      <div class="col-md-6">
                        <small class="text-muted">Service Name</small>
                        <h4 class="fs-6">2 Day delivery service</h4>
                      </div>
                      <div class="col-md-6">
                        <small class="text-muted">Estimated Time</small>
                        <h4 class="fs-6">2 Days</h4>
                      </div>
                    </div>
                  </section>

                  <hr class="m-4"/>
                  

                  <section class="ps-4">
                    <h3 class="fs-5 pb-3">Shipping Items</h3>
                    <div class="row">
                      {state.shipmentDetail.items.map((item)=>
                      <>
                      <div class="col-md-12">
                        <h4 class="fs-6">{item.item_description}</h4>
                        <small class="text-muted">{item.item_weight} KG - {item.item_height}x{item.item_width}x{item.item_length} m</small>
                      </div>
                      
                      </>
)}
                    </div>
                  </section>
                  
                  
            </section>

            
  
          </div>
        </div>
      </section>
      
      </div>            
             
    </div>
    
  )
}

export default Shipment_detail