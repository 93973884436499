import React, { useEffect,useState } from 'react'
import Left_panel from '../Left_panel'
import Sub_header from '../Sub_header'
import axios from 'axios'
import exportValue from '../../apiconfig'
import Swal from 'sweetalert2'
import Modal from 'react-bootstrap/Modal';
import ReactLoading from 'react-loading';
import Moment from 'react-moment';
import '../../components/loader.css';
import TablePagination from '@mui/material/TablePagination';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap"
import { useNavigate } from 'react-router-dom'





const Shipments = () => {
    let navigate = useNavigate();
    const [state, setState] = React.useState({
        shipmnent_list: [],
        dataCount: 0,
        isLoading: true,
        dialogOpen: false,
        searchValue: "",
       

    })
    const search = window.location.search;
    // console.log("search -   ",search);
    const params = new URLSearchParams(search);
    let title= params.get('title')
    let juu = search.split("?status=");
    // console.log("params 00  -- - -  - - -  ",juu)
    let shipmetFilterUrl = [];
    if(juu[1]) {
    // console.log("params  -- - -  - - -  ",juu[1].split('%26'))
     shipmetFilterUrl = juu[1].split('%26');
    }
    //let status = params.get('status');
    //console.log("status====",status)
   

  const [shipmentstate, setshipStatus] = React.useState({ shipment_status: null });
const[modals,setModals] = React.useState({show: false,invoice_id:""})
const [otherStates,setOtherState] = React.useState({activePage:1,rowsPerPage:10,page:0,total_count:0,onload:true});
const [statusfilters, setstatusFilters] = React.useState({ current_status: shipmetFilterUrl })
const [searchfilters, setsearchFilters] = React.useState({ searchtitle: "",filter:[{name:"booked",value:1,status:true},{name:"assigned",value:2,status:true},{name:"intransit",value:3,status:true},{name:"delivered",value:4,status:true}],filterStatus:[1,2,3,4]})
const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})
const [isLoading, setIsLoading] = useState(false);



const dimension_detail = () =>{
    let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/company_info_api`;
    let sendData = {};
  
    axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
      setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
      console.log("rnmmmmm", res);
    }).catch((e) => {
      // toast.configure()
       //toast.error("Some thing went wrong")
      console.log("----error:   ", e);
    })
  }
  useEffect(() => {   
 //   axios_get_api() 
   
  }, [])

  useEffect(() => {
    
     axios_get_api()    
    
   }, [otherStates.rowsPerPage,searchfilters.filterStatus])



    const axios_get_api = (shipment_status,index=0,onLoad = true) => {
         console.log("dstatus--->",shipment_status)
         console.log("onLoad--->",onLoad)
         setIsLoading(true)
        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment_list`;
        let sendData = {
            find:"",
            shipment_status: shipment_status,
            current_status:"",
            indexValue: index,
            limit:otherStates.rowsPerPage
        };
        console.log("sendData",sendData)
        if(statusfilters.current_status.length>0){
            sendData.current_status = statusfilters.current_status
        }
        if(searchfilters.searchtitle != ""){
            sendData.find= searchfilters.searchtitle
        }
        if(searchfilters.filterStatus.length > 0) {
            sendData.status= searchfilters.filterStatus
        }
       
         console.log("send",sendData)
        axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
            setIsLoading(false)
            // console.log("index",index)
            // console.log("onLoad",onLoad)

            if(index==0  && onLoad) {
                setOtherState({...otherStates,total_count:res.data.dataCount})         
            }
           
         setState({ ...state, shipmnent_list: res.data.output, dataCount: res.data.dataCount,isLoading:false });
          
          console.log( "response",res.data.output);
        //      if (status != null) {
        //     console.log("loct - -   ", JSON.parse(status));
        //      setstatusFilters({...statusfilters, current_status:JSON.parse(status)})
        // }



        }).catch((e) => {
            setIsLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                
              })
            console.log("----error:   ", e);
        })

    }
    const searchUser = ( shipment_status,index=0) => {
        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment_find_detail`;
            
        let transporter_data= { indexValue:index, limit:otherStates.rowsPerPage, dstatus:1,title:"", shipment_status: shipment_status,};
        // console.log("pv",state.title)
        if ( searchfilters.searchtitle) {
           transporter_data.title=  searchfilters.searchtitle;
         }
         console.log("transporter",transporter_data)
       
    axios.post(full_api,transporter_data, { headers: exportValue.headers }).then(res => {        
//    setState({ ...state,searchValue:res.data.title})
   console.log( "response123",res);
   if(index==0 && otherStates.onload) {
    setOtherState({...otherStates,total_count:res.data.dataCount,onload:false})         
}  
   setState({ ...state, shipmnent_list: res.data.output, dataCount: res.data.dataCount,isLoading:false });
       
   
   

   }).catch((e) => {
    Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        
      })
       console.log("----error:   ", e);
   })          
  }

    const stateHandler = (e) => {    
        setsearchFilters({ ...searchfilters, searchtitle: e.target.value })
    }

    const searchOnkeyPress = (e,val) =>{
        console.log("e=>    ",e);
        console.log("val=>    ",val);
        if((e.charCode == 13 || val)) {
            axios_get_api()
        }
    }


const filter=(e,index)=>{
    console.log("e=>    ",e);
    let tempFilter = {...searchfilters.filter};
    let tempFilterStatus = [...searchfilters.filterStatus];
    tempFilter[index].status = e.target.checked;
    if(e.target.checked) {       
        const indexf = tempFilterStatus.indexOf(tempFilter[index].value); 
        if(indexf == -1){
            tempFilterStatus.push(tempFilter[index].value);
        }
    }else {
        const index1 = tempFilterStatus.indexOf(tempFilter[index].value);
        tempFilterStatus.splice(index1,1)
    }
    console.log("tempFilterStatus=>    ",tempFilterStatus);
    setsearchFilters({ ...searchfilters, filter: tempFilter,filterStatus:tempFilterStatus })
}
React.useEffect(()=>{
    if (title) {
       // setsearchFilters({...searchfilters,searchtitle:title})
    }
//searchUser(null,0)
// axios_get_api(null,0,true);


},[])
React.useEffect(()=>{
    if (statusfilters.current_status.length > 0) {   
        params.delete('status');
        // console.log("current_status ===========>    ",statusfilters.current_status); 
        // console.log("current_status ===========>    ",statusfilters.current_status.join('&'));        
        params.append('status', statusfilters.current_status.join('&'))
      
    }
    else{
        
         
    }
    
   // axios_get_api(null,0,true);

    navigate({ search: params.toString() })
  
},[statusfilters])

    
    const shipmentFilter = (dstatus) => {
         console.log("action ",dstatus);
        setshipStatus({ ...shipmentstate, shipment_status: dstatus })
      
        axios_get_api(dstatus,0,true)
        
      }
   
    function deleteship(invoice_id) {
        let userdata = {invoice_id:invoice_id}
        axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment_remove` , userdata ,{ headers: exportValue.headers }).then((res)=>{
       
         setState({...state,invoice_id:invoice_id});
         setModals({...modals, show:false})
         console.log("res",res)
         axios_get_api();
         if(res.data.status==200){
            Swal.fire(
                'Good job!',
                'Shipment is Deleted Successfully !',
                'success'
              )
         }
         else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                
              })
         }
         }).catch((e) => {
   
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                
              })
           
         })
     }
     function shipment_active_inactive(invoice_id,dstatus) {
        let userdata = {invoice_id:invoice_id, dstatus:dstatus}
        axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment_status` , userdata ,{ headers: exportValue.headers }).then((res)=>{
       
         setState({...state,invoice_id:invoice_id});
         console.log("res",res)
         axios_get_api();
         if(res.data.status==200){
            Swal.fire(
                'Good job!',
                'Status Changed Successfully !',
                'success'
              )
         }
         else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                
              })
         }
         }).catch((e) => {
   
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                
              })
           
         })
     }
     const handlePageChange = (event,newPage) =>{
        // console.log("newpage",newPage)
        setOtherState({...otherStates,page:newPage})
        //console.log("newPage ", newPage);
        axios_get_api(shipmentstate.shipment_status,newPage);
        
       // searchUser(state.searchValue,newPage)
    }
    const handleChangeRowsPerPage = (event,newPage) => {
     console.log("event ", event.target.value);
     console.log("newPage ", newPage);
       setOtherState({...otherStates, rowsPerPage:event.target.value, page:0})
       
       
    }
    const deleteConfirm = (invoice_id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            //console.log("result  ",result);
            if(result.isConfirmed) {
                deleteship(invoice_id)
            }       
        })

    }
  
    const handleStatusChange = (e) => {
        const checked = e.target.checked;
        console.log("checked",checked)
        const selectedStatus = e.target.value;
        console.log("selectedStatus",selectedStatus)

        if (checked) {
            setstatusFilters(prevState => ({
                current_status: [...prevState.current_status, selectedStatus]
            }));
        } else {
            setstatusFilters(prevState => ({
                current_status: prevState.current_status.filter(current_status => current_status !== selectedStatus)
            }));
        }

        // status=null
        // params.delete('status')
        // if (statusfilters.current_status.length > 0) {           
        //     params.append('status', JSON.stringify(statusfilters.current_status))
        // }
        // axios_get_api();
        // navigate({ search: params.toString() })

        // status=null
        // params.delete('status')
        // if (statusfilters.current_status.length > 0) {           
        //     params.append('status', JSON.stringify(statusfilters.current_status))
        // }
        // axios_get_api();
        // navigate({ search: params.toString() },{ replace: true })


        //ShipmentList()
    }

    const currentStatusIschecked = (value) => {
        // console.log("current_status",value)       
        let index = statusfilters.current_status.indexOf(value);
        // console.log("index",index)
       
        return (index != -1) ? true : false;
       
    }

     const status_update = (shipment_id)=>{

     let full_api = "https://shiprocketbackend.fniix.com/v.1.0.1/web/query/shipment/shipment_tracking"
     let sendData= {trackingNumber:shipment_id};
        console.log("senddata",sendData)
       
     axios.post(full_api,sendData, { headers: exportValue.headers }).then(res => {        
      
        console.log("res",res)
        if(res.data.message=="success"){
           
            Swal.fire(
                'Success!',
                ' Status Updated Successfully !',
                'success'
              )
              window.location.reload(false);
        }

        
        else{
            Swal.fire(
                'Unsuccess!',
                'Something Went Wrong !',
                'success'
              )
              window.location.reload(false);
       
        }
    })

     }
  

  return (
    <div>
        <Sub_header/>
        <div style={{marginLeft:"15px",marginRight:"15px"}}>
         <section class="mt-3">
        <div class="row">
            <div class="col-12 col-md-5">
                <h5>All Shipments</h5>
            </div>
            <div class="col-12 col-md-4">
            <div class="btn-toolbar mb-2 mb-md-0">
                    <div class="input-group me-2 me-lg-3 fmxw-400">
                        <span class="input-group-text" onClick={(event) => searchOnkeyPress(event,"click")}>
                            <svg class="icon icon-xs" x-description="Heroicon name: solid/search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
                            </svg>
                        </span>
                        <input type="text" class="form-control" placeholder="Search Shipments" onChange={(e)=>stateHandler(e)} onKeyPress={(event) => searchOnkeyPress(event)}/>
                    </div>
                    <div class="btn-group">
                        <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown"><i class="fa fa-filter" aria-hidden="true"></i> &nbsp;
                            Filter <i class="fa fa-angle-down" aria-hidden="true"></i>
                        </button>
                        <div class="dropdown-menu">
                            <section class="p-2">
                            <h6>Filter</h6>
                            <hr/>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="check1" name="booked" value="something" defaultChecked={searchfilters.filter[0].value} onChange={(e)=>filter(e,0)}/>
                                    <label class="form-check-label">Booked</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="check1" name="assigned" value="something" defaultChecked={searchfilters.filter[1].value} onChange={(e)=>filter(e,1)}/>
                                    <label class="form-check-label">Assiged</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="check1" name="intransit" value="something" defaultChecked={searchfilters.filter[2].value} onChange={(e)=>filter(e,2)}/>
                                    <label class="form-check-label">In Transit</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="check1" name="delivered" value="something" defaultChecked={searchfilters.filter[3].value} onChange={(e)=>filter(e,3)}/>
                                    <label class="form-check-label">Delivered</label>
                                </div>
                            </section>
                          
                        </div>
                      </div>

                </div>
            </div>
            {/* <div class="col-12 col-md">
                <ul class="nav nav-tabs justify-content-end">
                    <li class="nav-item">
                      <a class="nav-link active" href="#">All</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#">Active</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#">Inactive</a>
                    </li>
                </ul>
            </div> */}
        </div>
        {(isLoading) ?
                <div style={{ backgroundColor: "#f1f5f9", zIndex: 2, height: "100%", width: "100%", position: "absolute", opacity: "0.4" }}>
                    <div style={{ left: "47%", top: "45%", position: "absolute" }}>
                        <ReactLoading type={"spin"} color={"#000000"} height={300} width={89} />
                    </div>
                </div> : 
            
                 <div class="card card-body border-0 shadow table-wrapper table-responsive mt-3">
                 
                
                <table class="table table-hover table-bordered shipm_tablew">
                    <thead>
                        <tr>
                            <th class="border-gray-200">Shipment Id #</th>	
                            <th class="border-gray-200">Origin</th>
                            <th class="border-gray-200">Destination</th>	
                            <th class="border-gray-200 ship_pickup">Sender</th>
                            <th class="border-gray-200">Receiver</th>
                            <th class="border-gray-200">Amount</th>
                            <th class="border-gray-200">Carrier</th>
                            <th class="border-gray-200">Status</th>
                            <th class="border-gray-200">Action</th>
                        </tr>
                    </thead>
                   
                    <tbody>
                      {state.shipmnent_list.map((item)=>
                        <tr>
                            <td>
                                <a href={"/shipment_detail/"+item.shipment_id} class="text-info fw-700 fs-6">{item.shipment_id}</a> <br/>
                                <span class="fw-normal"><Moment format="MMM DD YYYY hh:mm">
                        {new Date(item.created*1000)}
            </Moment> 
            {/* <small>12:23</small> */}
            </span>
                            </td>                        
                            <td>
                            {item.sender[0].city}    <br/>
                            <small>{item.sender[0].country}</small>
                            </td>                        
                            <td>
                            {item.receiver[0].city}    <br/>
                            <small>{item.receiver[0].country}</small>
                            </td>
                            <td class="">
                                <strong>{item.sender[0].name}</strong>
                            </td>
                            <td>
                               <strong>{item.receiver[0].name}</strong>
                            </td>
                            <td class="fw-700 text-danger">
                                ${item.total_amount}
                            </td>
                            <td>
                                <img src={`https://shiprocketcdn.fniix.com/images/${item.carrier_image}`} alt="" class="img-fluid"/>
                            </td>
                            <td>
                            {(item.status == 1)?
                            <span class="badge rounded-pill bg-info ">Booked</span>:
                            (item.status == 2)?
                            <span class="badge rounded-pill bg-warning ">Picked Up </span>:
                            (item.status == 3)?
                            <span class="badge rounded-pill bg-gray-500 ">In transit</span>:
                            (item.status == 4)?
                            <span class="badge rounded-pill bg-success ">Out For delivery</span>:
                            (item.status==5)?
                            <span class="badge rounded-pill bg-success ">Delivered</span>
                            : (item.status==6)?
                            <span class="badge rounded-pill bg-success ">Cancelled</span>:""
                            }
                            </td>
                            <td>
                               {(item.shipping_label && item.shipping_label != "") ?<a href={item.shipping_label}><button type="button" class="btn btn-outline-danger  btn-sm"><i class="material-icons-outlined fs-20"  data-bs-toggle="tooltip" title="Download Label">qr_code</i></button></a> :""}

                               <a href={"/print_receipts/"+item.shipment_id} target="_blank"><button type="button" class="btn btn-outline-info  btn-sm"><i class="material-icons-outlined fs-20" data-bs-toggle="tooltip" title="Print Receipt">receipt</i></button></a>

                               <a href={"/print_invoice/"+item.shipment_id} target="_blank"> <button type="button" class="btn btn-outline-success  btn-sm"><i class="material-icons-outlined fs-20" data-bs-toggle="tooltip" title="Invoice">article</i></button></a>
                                {/* <button type="button" class="btn btn-outline-danger  btn-sm"><i class="material-icons-outlined fs-20" data-bs-toggle="tooltip" title="Delete">delete_outline</i></button> */}
                                
                               <a href={"https://shiprocket.netlify.app/tracking/"+item.shipment_id} target="_blank"><button type="button" class="btn btn-outline-primary  btn-sm"><i class="material-icons-outlined fs-20" data-bs-toggle="tooltip" title="Update Status">search</i></button></a> 
                               <a onClick={()=>status_update(item.shipment_id)} class="btn btn-outline-primary  btn-sm"><i class="material-icons-outlined fs-20" data-bs-toggle="tooltip" title="Update Status">refresh</i></a>
                           

                            </td>
                        </tr>
                      )}                        
                    </tbody>

                </table>
                <TablePagination
                            component="div"
                            rowsPerPageOptions={[5, 10]}
                            count={otherStates.total_count}
                            page={otherStates.page}
                            onPageChange={handlePageChange}
                            rowsPerPage={otherStates.rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}

                        />
                        
                      
                        </div> 
}
                      
                {/* <div class="card-footer px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination mb-0">
                            <li class="page-item">
                                <a class="page-link" href="#">Previous</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">1</a>
                            </li>
                            <li class="page-item active">
                                <a class="page-link" href="#">2</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">3</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">4</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">5</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">Next</a>
                            </li>
                        </ul>
                    </nav>
                    <div class="fw-normal small mt-4 mt-lg-0">Showing <b>5</b> out of <b>25</b> entries</div>
                </div> */}
            
                    

    </section>
    </div>

        </div>
  )
}

export default Shipments